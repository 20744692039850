<template>
  <div class="section-whats d-flex justify-content-center ">
    <div class="main-whatsapp   py-5 px-3 px-md-5 text-center shadow">
      <img
        style="width: 101px; height: 101px"
        src="@/assets/images/whatsapp-img.png"
      />
      <h1>WhatsApp Setting</h1>
      <p>Choose WhatsApp protocol</p>

      <div class="mt-5">
        <b-form-radio-group
          v-model="selectedProtocol"
          class="dt-radio-button mb-3 d-sm-flex justify-content-center gap-4"
        >
          <div
            class="desktop-content d-flex justify-content-between"
            :class="{ active: this.selectedProtocol === 'desktop' }"
          >
            <label for="desk" class="out-wraper" style="cursor: pointer;">
              <img
                style="width: 30px; height: 30px;"
                src="@/assets/images/desktop.png"
              />
              Desktop
              <p class="whats-text">
                Select this option if you want to use whatsApp desktop app
              </p>
            </label>
            <b-form-radio value="desktop" id="desk"> </b-form-radio>
          </div>

          <div
            class="mobile-content d-flex justify-content-between "
            :class="{ active: this.selectedProtocol === 'mobile' }"
          >
            <label for="Mob" class="out-wraper" style="cursor: pointer;">
              <img
                style="width: 30px; height: 30px;"
                src="@/assets/images/mobile.png"
              />
              Web
              <p class="whats-text">
                Select this option if you want to use whatsApp web app
              </p>
            </label>
            <b-form-radio value="mobile" id="Mob"> </b-form-radio>
          </div>
        </b-form-radio-group>
      </div>

      <div class="d-flex justify-content-center sign-btn mt-5">
        <button class="btn secondary-btn btn-block" @click="handleSubmit">

    <div v-if="this.isLoading" style="font-size: 12px;">
  <b-spinner label="Loading..."></b-spinner>
</div>
<div v-else>
  Update
    </div>

        </button>
      </div>
    </div>
  </div>
</template>

<script>
// D:\Projects\razot\datagma\datagama\src\app\store\constant\actions.type.js
import {
  GET_WHATSAPP_PROTO,
  UPDATE_WHATSAPP_PROTOCOL,
} from "../../../store/constant/actions.type";

export default {
  data() {
    return {
      selectedProtocol: this.$store.getters.getWhatsAppPro,
      isLoading:false
    };
  },
  beforeMount() {
    const whatsappProtocol = this.$store.getters.getWhatsAppPro;
    if (!whatsappProtocol || whatsappProtocol == null) {
      try {
        this.$store
          .dispatch(GET_WHATSAPP_PROTO)
          .then((response) => {
            console.log("res", response);
            this.selectedProtocol = response?.data[0]?.whatapp_protocol;
          })
          .catch((err) => {
            if (err != undefined) {
              console.log("error", err);
            }
          });
      } catch (error) {
        console.error("error", error);
      }
    } else {
      console.log("second##");
      this.selectedProtocol = whatsappProtocol;
    }
  },

  methods: {
    handleSubmit() {
     
       this.isLoading = true 
      console.log("aterval", this.selectedProtocol);
      const payload = {
        Protocol: this.selectedProtocol,
      };

      this.$store
        .dispatch(UPDATE_WHATSAPP_PROTOCOL, payload)
        .then((response) => {
          if (
            response &&
            response.result === "Whatsapp Protocol updated Successfully."
          ) {
            this.isLoading = false 
            this.$store.dispatch("changeWhatsAppPro", this.selectedProtocol);
            this.flashMessage.success({
              title: "Success",
              message: response?.result,
            });
          }
        })
        .catch((err) => {
          this.flashMessage.error({
            title: "Error",
            message: err.error,
          });
        });
    },
  },
};
</script>

<style>
.main-whatsapp {
  border-radius: 20px;
}
.main-whatsapp h1 {
  font-size: 50px;
  margin-top: 16px;
}
.main-whatsapp p {
  font-size: 20px;
  color: #616161;
  font-weight: 500;
  margin-top: 13px;
}
.main-whatsapp button.btn.secondary-btn {
    max-width: 236px;
    width: 100%;
    height: 58px;
    font-size: 20px;
    font-weight: 500;
    border-radius: 10px;
    background: #3F8AFF;
    border-color: #3F8AFF;
}

.desktop-content,
.mobile-content {
  max-width: 383px;
  width: 100%;
  border: 2px solid #b6b6b6;
  border-radius: 6px;
  height: 90px;
  align-items: center;
  padding: 2px 0px 0px 15px;
  background: #f8f8f8;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
}
.main-whatsapp {
  width: 50%;
}

.main-whatsapp .bv-no-focus-ring {
  gap: 23px;
}

.main-whatsapp .custom-radio .custom-control-label::before {
  border-radius: 50%;
  width: 27px;
  height: 27px;
  top: 0px;
}
.main-whatsapp .custom-control-label::after {
  height: 20px;
  width: 26px;
  font-size: 18px;
  background-size: 18px;
}

.main-whatsapp:before {
  background: url(../../../../assets/images/before-whatsapp.png);
  content: "";
  height: 137px;
  width: 138px;
  position: absolute;
  background-repeat: no-repeat;
  right: -54px;
  top: -36px;
}
.main-whatsapp::after {
  background: url(../../../../assets/images/after-whatesapp.png);
  content: "";
  height: 137px;
  width: 138px;
  position: absolute;
  background-repeat: no-repeat;
  left: -37px;
  bottom: -20px;
}
.main-whatsapp {
  position: relative;
  overflow: hidden;
}
.out-wraper .whats-text {
  font-size: 12px;
  line-height: normal;
  margin: 0;
  margin-top: 8px;
}
.out-wraper {
  text-align: left;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background: #0f3274;
  border-radius: 100%;
  width: 17px;
  height: 17px;
  left: -19px;
  top: 5px;
  background-image: none;
}
body .custom-control-input:checked ~ .custom-control-label::before {
  box-shadow: inset 0px 1.05882px 7.05882px rgba(0, 0, 0, 0.15);
  background-color: transparent;
  border: none;
  background: #fff;
  border: 1px solid #0f3274;
  background-image: none;
}
.main-whatsapp .active {
  border: 2px solid #0f3274;
}
.main-whatsapp button.btn.secondary-btn:focus {
    box-shadow: none;
}
@media screen and (max-width: 1400px) {
  .main-whatsapp {
    width: 70%;
  }
}
@media screen and (max-width: 1200px) {
  .main-whatsapp {
    width: 93%;
  }
}

@media screen and (max-width: 600px) {
  .main-whatsapp {
    width: 100%;
  }
  .dt-radio-button {
    flex-direction: column;
  }
  .main-whatsapp h1 {
    font-size: 33px;
  }
  .desktop-content,
  .mobile-content {
    max-width: 100%;
    margin-bottom: 16px;
  }
}
</style>
